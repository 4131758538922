* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

html, body {
  font-size: 62.5%;
  font-family: sans-serif;
  height: 100%;
}